body {
  margin: 0;
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-3 {
  margin-top: 3px;
}

.mg-top-bottom-40 {
  margin: 40px 0 40px 0;
}

.mg-top-bottom-20 {
  margin: 20px 0 20px 0;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-left-20 {
  margin-left: 20px;
}

.mg-left-30 {
  margin-left: 30px;
}

.mg-right-20 {
  margin-right: 20px;
}

.mg-right-65 {
  margin-right: 65px;
}

.mg-right-55 {
  margin-right: 55px;
}

.pd-2 {
  padding: 2px;
}

.pd-top-2 {
  padding-top: 2px;
}

.pd-bottom-top-2 {
  padding-bottom: 2px;
  padding-top: 2px;
}

.pd-right-2 {
  padding-right: 2px;
}

.imgView {
  object-fit: cover;
}

.border-5 {
  border-radius: 5;
  padding: 2px;
}

.rows {
  display: flex;
  flex-direction: row;
}

.flex-page {
  display: flex;
  justify-content: center;
}

.rowsJ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.centerItens {
  display: flex;
  justify-content: center;
}

hr {
  color: #d9d9ce;
}



body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}
* {
  margin: 0px;
  padding: 0px;
  outline: 0px;
}
button,
i,
svg {
  cursor: pointer;
}


span {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

form {
  margin: 0;
  padding: 0;
}

.navlink {
  text-decoration: none;
}

.flex-page {
  display: flex;
  justify-content: center;
}

.fullWidth {
  width: 100%;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rows-align {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-between-no-center {
  display: flex;
  justify-content: space-between;
}

.mg-0 {
  margin: 0;
}

.mg-tb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-top-15 {
  margin-top: 15px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-40 {
  margin-top: 40px;
}

.mg-top-50 {
  margin-top: 60px;
}

.mg-top-bottom-40 {
  margin: 40px 0 40px 0;
}

.mg-top-bottom-16 {
  margin: 16px 0px;
}

.mg-bottom-16 {
  margin-bottom: 16px;
}

.mg-top-bottom-20 {
  margin: 20px 0 20px 0;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-bottom-35 {
  margin-bottom: 35px;
}

.mg-bottom-50 {
  margin-bottom: 50px;
}

.mg-bottom-55 {
  margin-bottom: 55px;
}

.mg-left-20 {
  margin-left: 20px;
}

.mg-left-21 {
  margin-left: 21px;
}

.mg-left-8 {
  margin-left: 8px;
}

.mg-right-8 {
  margin-right: 8px;
}

.mg-left-10 {
  margin-left: 10px;
}

.mg-right-10 {
  margin-right: 10px;
}

.mg-left-15 {
  margin-left: 15px;
}

.mg-left-30 {
  margin-left: 30px;
}

.mg-right-20 {
  margin-right: 20px;
}

.mg-right-30 {
  margin-right: 30px;
}

.mg-right-65 {
  margin-right: 65px;
}

.mg-right-45 {
  margin-right: 55px;
}

.mg-right-40 {
  margin-right: 40px;
}

.mg-right-40 {
  margin-right: 55px;
}

.mg-left-40 {
  margin-left: 40px;
}

.mg-right-55 {
  margin-right: 55px;
}

.mg-right-50 {
  margin-right: 55px;
}

.mg-rl-40 {
  margin: 0px 40px;
}

.pd-right-15-10 {
  padding-right: 15px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
}

.pd-2 {
  padding: 2px;
}

.pd-top-2 {
  padding-top: 2px;
}

.pd-bottom-top-2 {
  padding-bottom: 2px;
  padding-top: 2px;
}

.pd-left-right {
  padding: 0px 20px;
}

.pd-right-2 {
  padding-right: 2px;
}

.imgView {
  object-fit: cover;
}

.border-5 {
  border-radius: 5;
  padding: 2px;
}

.rows {
  display: flex;
  flex-direction: row;
}

.rows-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowsPlan {
  display: flex;
  align-items: center;
}

.flex-page {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.rowsJ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowsJSpace {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.borderTopRigth {
  border-top-right-radius: 5px;
}

.borderBottomRigth {
  border-bottom-right-radius: 5px;
}

.centerItens {
  display: flex;
  justify-content: center;
}

.centerAll {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.spacebt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .bottomPage {
    display: none;
  }

  .flex-pageCard {
    display: flex;
    justify-content: center;
  }

  .mobileMenus {
    display: none;
  }

  .centerMobile {
    display: flex;
    justify-content: center;
  }

  .mg-20-top-mobile {
    margin-top: 20px;
  }

  /* .viewMenu{
    display: flex;
    justify-content: center;
    padding: 10px 16px;
    box-sizing: border-box;
  } */
}

@media only screen and (min-width: 992px) {
  .topPage {
    display: none;
  }
}



.iconBblack:active {
  background: #f2f2ed;
  color: #50b275;
  padding: 17px;
  border-radius: 100%;
}
.lato {
  font-family: Lato Regular;
}

button {
  cursor: pointer;
}

.grecaptcha-badge {
  display: none !important;
}

/* --------- */
/* #mobile__slider__preview {
} */

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 601px) and (max-width: 991px) {
  #mobile__slider__preview {
    height: 320px;
  }
}

@media only screen and (max-width: 600px) {
  #mobile__slider__preview {
    height: 280px;
  }
} 


hr{
  border: 1px solid var(--unnamed-color-d9d9d4);
  border: 1px solid #D9D9D4;
  
}