/* ===================== */
/* ==== Botão close ==== */
/* ===================== */
.swal2-container.swal2-center {
  z-index: 300000000;
}

.swal2-confirm {
  border: none;
  background: transparent;
  outline: none;
}

.btn-close-alert {
  position: absolute;
  top: 20px;
  right: 30px;
  outline: none !important;
  background: none !important;
  -webkit-tap-highlight-color: transparent !important;
  border: none !important;
}

.btn-close-alert::after {
  content: '';
  position: absolute;
  width: 4px;
  border-radius: 4px;
  height: 20px;
  transform: rotate(45deg);
  background: #50b275;
}
.btn-close-alert::before {
  content: '';
  position: absolute;
  width: 4px;
  border-radius: 4px;
  height: 20px;
  transform: rotate(-45deg);
  background: #50b275;
}
/* =========================== */
/* ===== Box and content ===== */
/* =========================== */
.swal2-header.header-alert {
  display: flex;
  flex-direction: row;
  max-width: max-content;
}
.swal2-content.description-alert {
  text-align: left !important;
  width: 100%;
  font-size: 16px;
  font-family: 'Lato Regular';
  color: #5b6372;
}

.swal2-title {
  font-size: 18px !important;
  font-family: 'Lato Black Italic';
  margin-bottom: 0px !important;
  margin-left: 10px !important;
  text-align: left !important;
}

@media (max-width: 800px) {
  .swal2-title {
    text-align: center !important;
    margin-left: 0px !important;
    margin-bottom: 10px !important;
    max-width: 80% !important;
  }
}

@media (max-width: 800px) {
  .swal2-header {
    padding: 0;
  }
  .swal2-header.header-alert {
    max-width: inherit;
    flex-direction: column;
  }
  .swal2-content.description-alert {
    text-align: center !important;
    width: 100%;
  }
}
/* =============== */
/* ==== Ícone ==== */
/* =============== */
.swal2-icon.icon-alert {
  flex: none;
}
/* =============== */
/* ==== Error ==== */
/* =============== */
.swal2-icon.swal2-error {
  border-color: #cc2c29;
  color: #cc2c29;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  background-color: #cc2c29;
}
.title-alert-error {
  color: #cc2c29;
}
/* ================= */
/* ==== Success ==== */
/* ================= */
.title-alert-success {
  color: #6b915c;
}
.swal2-icon.swal2-success {
  border-color: #6b915c;
  color: #6b915c;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #6b915c;
}
/* ================= */
/* ==== Warning ==== */
/* ================= */
.title-alert-warning {
  color: #a88721;
}
.swal2-icon.swal2-warning {
  border-color: #a88721;
  color: #a88721;
}
